import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slide.css";

const settings = {
  adaptiveHeight: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: false,
  infinite: true,
  speed: 0,
  fade: true,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const PageTemplate = ({ imageAlt, slides, menu, children }) => (
  <section className="h-screen relative bg-black flex justify-center items-start">
    <StaticImage
      src={"../images/road-sign-_VIT0003-.jpg"}
      alt={imageAlt}
      className="absolute inset-0 object-cover mx-auto"
    />
    <div className="absolute inset-0 overflow-hidden flex justify-center items-center">
      <div className="relative h-screen fixed-width perspective">
        <div className="absolute bg-gray-900 bg-dots flex items-center sign">
          <div
            style={{ marginTop: "3%", maxWidth: "100vw" }}
            className="font-display uppercase text-yellow text-center w-full h-full leading-loose led-shadow lg:p-6 mx-auto parsed-content"
          >
            <Slider {...settings}>
              {slides ? (
                slides.map((slide) => (
                  <div
                    key={slide.content}
                    className={`h-full led-${slide.fontSize}`}
                  >
                    {slide.link ? (
                      <a
                        href={slide.link}
                        className="h-full p-4 flex items-center justify-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: remark()
                              .use(recommended)
                              .use(remarkHtml)
                              .processSync(slide.content)
                              .toString(),
                          }}
                        ></div>
                      </a>
                    ) : (
                      <div className="h-full p-4 flex items-center justify-center">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: remark()
                              .use(recommended)
                              .use(remarkHtml)
                              .processSync(slide.content)
                              .toString(),
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <>{children}</>
              )}
            </Slider>
          </div>
        </div>
      </div>
      <div className="absolute bottom-4 right-4 uppercase text-xs">
        <ul>
          {menu &&
            menu.map((menuItem) => (
              <li key={menuItem.label} className={`mt-1 text-right`}>
                <a
                  href={menuItem.link}
                  className="text-white opacity-40 hover:opacity-100 transition"
                  target="_blank"
                  rel="noreferrer"
                >
                  {menuItem.label}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  </section>
);

export default PageTemplate;
